@use './theme/colorVariables' as *;


@use "@material/theme" with ( 
    $primary: $primary-main,
    $secondary: $secondry-main,
    $on-primary: $white,
    $on-secondary: $action-disabled,
    $error: $error-main,
    $on-error: $error-background,
);
  
@use '@material/banner/styles' as banner-styles;
@use '@material/button/mdc-button';
@use "@material/button";
@use '@material/card/mdc-card';
@use '@material/checkbox/mdc-checkbox';
@use '@material/chips/deprecated/mdc-chips';
@use '@material/circular-progress/mdc-circular-progress';
// Select's styles must be emitted before datatable's due to equal specificity.
@use '@material/select/mdc-select';
@use '@material/select';
@use '@material/data-table/mdc-data-table';
@use '@material/dialog';
@use '@material/drawer';
@use '@material/elevation/mdc-elevation';
@use '@material/fab/mdc-fab';
@use '@material/floating-label/mdc-floating-label';
@use '@material/form-field/mdc-form-field';
@use '@material/icon-button';
@use '@material/image-list/mdc-image-list';
@use '@material/layout-grid/mdc-layout-grid';
@use '@material/line-ripple/mdc-line-ripple';
@use '@material/linear-progress/mdc-linear-progress';
@use '@material/list/mdc-list';
@use '@material/menu/mdc-menu';
@use '@material/menu-surface/mdc-menu-surface';
@use '@material/notched-outline/mdc-notched-outline';
@use '@material/radio/mdc-radio';
@use '@material/ripple/mdc-ripple';
@use '@material/segmented-button/styles' as segmented-button-styles;
@use '@material/slider/styles' as slider-styles;
@use '@material/snackbar/mdc-snackbar';
@use '@material/switch';
@use '@material/tab/mdc-tab';
@use '@material/tab-bar/mdc-tab-bar';
@use '@material/tab-indicator/mdc-tab-indicator';
@use '@material/tab-scroller/mdc-tab-scroller';
@use '@material/textfield/mdc-text-field';
@use '@material/tooltip/styles' as tooltip-styles;
@use '@material/top-app-bar/mdc-top-app-bar';
@use '@material/typography/mdc-typography';
@use '@material/textfield';
@use '@material/checkbox';
@use "@material/form-field";
@use "@material/radio/styles";
@use "@material/radio";
@use '@material/data-table';

@include drawer.core-styles;
@include drawer.modal-core-styles;
@include textfield.core-styles;
@include checkbox.core-styles;
@include form-field.core-styles;
@include dialog.core-styles;




.text-field-dense-4 {
    @include textfield.outlined-density(-4);
}
.mdc-button-dense-1 {
    @include button.density(-1);
}

.checkBox-color {
    @include checkbox.container-colors($text-secondry, $white, $primary-main, $primary-main, true );
    @include checkbox.disabled-container-colors ($action-disabled, $white, $action-disabled, $action-disabled);
    @include checkbox.ink-color($white);
    @include checkbox.disabled-ink-color($action-disabled);
    @include checkbox.focus-indicator-color($primary-main);
    @include checkbox.density(0);
}

.radio-color {
    @include radio.unchecked-stroke-color($text-secondry);
    @include radio.checked-stroke-color($primary-main);
    @include radio.ink-color($primary-main);
    @include radio.disabled-unchecked-stroke-color($action-disabled);
    @include radio.disabled-checked-stroke-color($action-disabled);
    @include radio.disabled-ink-color($action-disabled);
    @include radio.focus-indicator-color($primary-main);
    @include radio.density(0);
}

.select-dense-4 {
    @include select.outlined-density(-4);
}

.dialog-m {
    @include dialog.max-width(630px, 24px);
}

.datatable-density {
    @include data-table.density(-4);
    @include data-table.divider-color($divider);
}