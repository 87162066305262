﻿

$primary-main: #3D3273;
$primary-light: #5949A7;
$primary-dark: #6B59C9;
$primary-background: #3D32731A;

$secondry-main: #757575;
$secondry-light: #969696;
$secondry-dark: #5C5C5C;
$secondry-background: #75757514;


$error-main: #F44336;
$error-light: #E57373;
$error-dark: #D32F2F;
$error-background: #FDECEB;
$error-content: #621B16;

$warning-main: #FF9800;
$warning-light: #FFB74D;
$warning-dark: #DD7B19;
$warning-background: #FFF5E6;
$warning-content: #663D00;

$warning-missing-field: $warning-main;
$warning-missing-field-text: $warning-content;
$warning-missing-field-background: $warning-background;

$info-main: #2196F3;
$info-light: #64B5F6;
$info-dark: #1976D2;
$info-background: #E9F5FE;
$info-content: #0D3C61;


$success-main: #4CAF50;
$success-light: #81C784;
$success-dark: #388E3C;
$success-background: #EDF7EE;
$success-content: #1E4620;

$text-primary: #000000DE;
$text-secondry: #0000008A;
$text-disabled: #00000061;


$action-disabled: #00000042;
$action-hover: #0000000A;
$action-selected: #00000014;


$backdrop: #00000080;
$divider: #0000001F;
$snackbar: #323232;
$outline-border: #FFFFFF;
$rating: FFB400;

$black: #000000;
$white: #ffffff;
$drawer-hover: #9483FD;
$avatar-background: #F5F5F5;

$grey1: #00000026;
$greyHeaderBackgrounfiew: #0000001A;
$greyBackgoundView: #0000000D;
$greyBorder: #E0E0E0;
$greyScroll: #d9d9d9;
$greyBorder-dark: #BDBDBD;
$grey2: #424242;
$grey3: #212121;

$wp-background: #FAFAFA;

$highlightTab: #7C4DFF;
$highlightBackgroundTab: $grey2;

$Tooltip-background: #808080;

